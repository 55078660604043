import React from "react";
import { navigate } from '@reach/router';
import { Link } from 'gatsby';
import { getProfile, isAuthenticated } from '../services/auth';

import Layout from "../components/layout";

import PDRLogo from '../images/pdr-logo_2x.png';

import '../styles/landing.scss';

export default function Home() {
  if (isAuthenticated()) {
    navigate('/app/dashboard', { replace: true });
  }
  
  return (
    <Layout noNavbar={ true }>
      <div className="landing-logo-box">
        <img src={ PDRLogo } />
      </div>
      <div className="landing-login">
        <Link to="/app/dashboard">Log In</Link>
      </div>
    </Layout>
  );
}
